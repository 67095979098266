<template>  

    <div class="">

        <b-card title="Manual Wallet Topup">
            <b-spinner v-if="spinnerShow == true" style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow">
            </b-spinner>
                <b-row v-if="mainData == true"> 
                    <b-col cols="6">
                        <b-form-group
                            label="Enter CN"
                            label-for="cn"
                            >
                            <b-form-input
                                id="cn"
                                placeholder="Enter CN"
                                v-model='cn'
                            />
                        </b-form-group>
                    </b-col>
                   
                   
                  
                    
                    

                    
                    <br><br><br>
                    <b-col cols="12">
                        <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="sendForm"
                        >
                        Submit
                        </b-button>
                    </b-col>
                </b-row>
           
    
        </b-card>

        <b-card title="" v-show="toggle">
            <b-card-title class="text-right" v-show="button_toggle">
                <b-button
                    variant="success"
                    type="submit"
                    v-b-modal.modal-1
                    v-on:click="adjustBtn"
                >
                    Refund Shipping Charges
                </b-button>
            </b-card-title>
            <dl class="row">
              <dt class="col-sm-3">
                Tracking Number
              </dt>
              <dd class="col-sm-9">
                {{this.copyCN}}
              </dd>
            </dl><dl class="row">
              <dt class="col-sm-3">
                Seller Name
              </dt>
              <dd class="col-sm-9">
                {{this.seller_name}}
              </dd>
            </dl><dl class="row">
              <dt class="col-sm-3">
                Seller ID
              </dt>
              <dd class="col-sm-9">
                {{this.seller_id}}
              </dd>
            </dl><dl class="row">
              <dt class="col-sm-3">
                Brand Name
              </dt>
              <dd class="col-sm-9">
                {{this.brand_name}}
              </dd>
            </dl>
            <br>
            <h4>Tracking Details</h4>
            <!-- table -->
            <vue-good-table
            ref="unverified-sellers"
            mode="remote"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :isLoading="isLoadingProp" 
            
          
            >
            <div slot="loadingContent">
            <b-spinner
                ref="spinner_one"
                variant="primary"
                class="mr-1"
                type="grow"
                style="width: 4rem; height: 4rem;"
            />
            </div>
            
            <template
                slot="table-row"
                slot-scope="props"
            >

                <!-- Column: Name -->
                <!-- <span
                v-if="props.column.field === 'fullNamae'"
                class="text-nowrap"
                >
                <b-avatar
                    :src="props.row.avatar"
                    class="mx-1"
                />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span> -->

            
                <!-- Column: Action -->
                

            
                
               
                
                <span v-if="props.column.field === 'status_at'">
                    {{new Date(props.row.status_at).toLocaleString()}}
                </span>
                <span v-else-if="props.column.field === 'created_at'">
                    {{new Date(props.row.created_at).toLocaleString()}}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
               
            </template>

        
            </vue-good-table>
            <br><br>
            <h4>Wallet Transaction Details</h4>
            <!-- table -->
            <vue-good-table
            ref="unverified-sellers2"
            mode="remote"
            :columns="columns2"
            :rows="rows2"
            :rtl="direction"
            :isLoading="isLoadingProp" 
            
          
            >
            <div slot="loadingContent">
            <b-spinner
                ref="spinner_one"
                variant="primary"
                class="mr-1"
                type="grow"
                style="width: 4rem; height: 4rem;"
            />
            </div>
            
            <template
                slot="table-row"
                slot-scope="props"
            >

                <span v-if="props.column.field === 'type.name'">
                    <b-badge variant="light-danger">
                        {{ props.row.type.name }}
                    </b-badge>
                </span>
                <span v-else-if="props.column.field === 'created_at'">
                    {{new Date(props.row.created_at).toLocaleString()}}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
               
            </template>

        
            </vue-good-table>
    
        </b-card>


        <b-modal
          id="modal-1"
          title="Confirmation"
          ok-only
          ok-title="Yes"
          @ok="refundWallet"
          :hide-footer="alertMessage == 1"
      >
          <b-card-text>
              <span v-if="alertMessage == 0">
                
                  Are you sure you want to process further?
              </span>
              
              <span v-else-if="alertMessage == 1">
                  <span v-if="returnMessageStatus == 0">
                      <b-alert
                      variant="success"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
                  <span v-else-if="returnMessageStatus == 1">
                      <b-alert
                      variant="danger"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
              </span>
          </b-card-text>
      </b-modal>

        
    </div>
    
    

</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BAlert,BDropdownItem, BDropdown,BCard,BButton,BTabs,BTab,BFormFile,BForm,BRow,BCol,BSpinner,BCardText,BCardTitle,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import pages from '@/router/routes/pages'
import Ripple from 'vue-ripple-directive'
import reportStore from '@/store'

import VuePapaParse from 'papaparse'

export default {
  components: {
    VueGoodTable,
    vSelect,
    BAlert,
    BCardTitle,
    BCardText,
    BSpinner,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormFile,
    BTab,
    BTabs,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton,
    VuePapaParse,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
    tabIndex: 0,
      allData:null,
      log: [],
      pageLength: 10,
      totalRecords:0,
      cn:'',
      copyCN:'',
      seller_name:'',
      seller_id:'',
      brand_name:'',
      isLoadingProp: false,
      datas:[],
      componentKey:0,
      componentKey1:0,
      mainData:true,
      spinnerShow:false,
      toggle:false,
      button_toggle:true,
      alertMessage : 0,
      returnMessageStatus : 0,
      returnMessage : "",
      pass:0,

      columns: [
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Status Date',
          field: 'status_at',
        },
        {
          label: 'Created At',
          field: 'created_at',
        },
      ],
      columns2: [
        {
          label: 'Transaction Type',
          field: 'type.name',
        },
        {
          label: 'Credits Before',
          field: 'credits_before',
        },
        {
          label: 'Credits After',
          field: 'credits_after',
        },
        {
          label: 'Amount Charged',
          field: 'amount_charged',
        },
        {
          label: 'DateTime',
          field: 'created_at',
        },
        {
          label: 'Remarks',
          field: 'remarks',
        },
      ],
      rows: [],
      rows2: [],
      
    }
  },
  computed: {
    

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  methods: {
    sendForm(){
        var formData = new FormData();
        formData.append('cn', this.cn);
        this.mainData = false;
        this.spinnerShow = true;
        this.button_toggle = true;
        this.$http.post('refund-cn', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            // this.showTable = false;
            this.mainData = true;
            this.spinnerShow = false;

            if(res.data.error == '0'){
                this.copyCN = this.cn;
                this.cn = '';

                this.seller_name = res.data.data.seller_details.company_name;
                this.seller_id = res.data.data.seller_details.id;
                this.brand_name = res.data.data.seller_details.brand;

                this.rows = res.data.data.tracking_details;
                this.rows2 = res.data.data.wallet_transaction;
                this.isLoadingProp = false;
                this.toggle = true;
                this.pass = res.data.data.pass;
                if(res.data.data.pass == 3){
                    this.button_toggle = true;
                }
            } else{
                this.toast('danger', res.data.message, 'Refund CN'); 
            }
        })
    },

    getDate(date){
        var d = new Date(date);
        d.setMonth(d.getMonth() + 1);
        return d.getDate()+'-'+d.getMonth()+'-'+ d.getFullYear()+' '+d.getHours()+':'+d.getMinutes();
        
    },


    adjustBtn(){
      
      this.alertMessage = 0;
      this.returnMessageStatus = 0;
      this.returnMessage = "";
    },
    refundWallet: function (event) {
        this.alertMessage = 0;
        this.returnMessageStatus = 0;
        this.returnMessage = "";
        event.preventDefault();
        this.isLoadingProp = true;

        var formData = new FormData();
            formData.append('cn', this.copyCN);
            formData.append('seller_id', this.seller_id);
            
            this.$http.post('refund-wallet', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                    this.alertMessage = 1;
                    this.returnMessageStatus = res.data.error;
                    this.returnMessage = res.data.message;
                
            });
            this.isLoadingProp = false;
        this.isLoadingProp = false;
    
  },

    toast(variant = null, message = 'Toast body content', title = variant) {
				
        message = this.$createElement('strong', { class: 'mr-2' }, message)

        this.$bvToast.toast(message, {
            title: title,
            variant,
            solid: false,
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>